<template>
    <div class="meeting-screen-recording">
		<!-- 会议管理 详情 录屏记录 -->
		<div v-if="!errorShow" class="right-panel">
		    <loading :loadingShow="loadingShow"></loading>
		    <div v-if="!loadingShow" class="content-main">
				<div class="table-paging">
					<div class="basic-submit-btn" v-if="editState === 'edit'" style="padding: 10px ;background-color: #fff;">
						<el-upload action="/api/school/schoolMeetingAttachment/uploadFile"
                               :headers="recordsUploadHeaders" :data="recordsUploadData" :before-upload="uploadBefore"
                               :on-success="uploadSuccess" :accept="recordsAccept" :limit="10"
                               :show-file-list="true" ref="uploadRef">
                        <xk-button type="primary" style="margin-left: 0; margin-bottom: 10px">
                           上传视频
                        </xk-button>
                    </el-upload>
					</div>
                    <div v-if="editState != 'edit'" style="background-color: #fff;display: flex;padding: 16px 0 16px 16px;">
                        <img :src="require('@/assets/images/tip_icon.png')" style="width: 14px; height: 14px; margin-right: 7px;" alt="">
                        <span style="font-size: 12px;font-family: Microsoft YaHei;font-weight: 400;color: #6D6F73;">asf、avi、rmvb、mov、wmv格式的文件不支持预览</span>
                    </div>
					<table-data
                        ref="table" id="table"
						v-loading="loadingTable"
						:config="tableConfig"
						:table-data="tableData"
						backgroundHeader="#FAFBFC"
						headerColor='#595959'>
						<template v-slot:operation="slotData">
							<xk-button size="mini" v-if="editState === '' && slotData.data.sign == '1'&&slotData.data.isInvalid!='1'" @click="handlePreviewVideo(slotData.data)" type="primary">预览</xk-button>
							<!-- <a :href="slotData.data.url" v-if="editState === ''"
								style="width: 50px;margin-left: 12px; padding:7px 8px;
								color:#3C7FFF;background-color: #EDF4FA;border-radius: 2px;
								display: inline;">下载文件</a> -->
								<xk-button size="mini"  v-if="editState === ''&&slotData.data.isInvalid!='1'" @click="downlad(slotData.data.url,slotData.data.name)" type="primary">下载文件</xk-button>
							<xk-button size="mini"  v-if="editState === 'edit' && slotData.data.autoUpload =='0'" @click="handleDel(slotData.data)" type="danger">删除</xk-button>
						</template>
					</table-data>
				</div>
			</div>
		</div>
    </div>
</template>

<script>
import { Loading, debounce} from "common-local"
import TableData from "../Sub/TableData"
import { getToken } from "@/libs/auth"
import Player from 'xgplayer';
import { filePreview } from '@/libs/staticResources.js'
export default {
    components: { Loading, TableData },
    props: {
        meetingId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            loadingShow: false,
            errorShow: false,
			loadingTable: false,
			tableData: [],
			recordsUploadHeaders: {
			    Authorization: getToken()
			},
			recordsUploadData: {
			    meetingId: "",
			    type: ""
			},
			// 是否编辑
			editState: "",
			// 会议纪要表格配置数据
			tableConfig: {
			    check: false,
			    height:'',
			    thead: [{
					label: '文件名称',
					prop: 'name'
				},{
					label: '上传时间',
					prop: 'createTime',
                    align: "left",
                    type: "function",
                    callBack: (row) => {
                        if (row.createTime) {
                            return row.createTime.slice(0, 16)
                        }
                    }
				},{
					label: '上传人',
					prop: 'createByName'
				},{
					label: '失效日期',
					// prop: 'expirationTime',
                    type: 'function',
                    callBack(row) {
                            return row.expirationTime?.slice(0,10)
                    }
				},{
					label: '状态',
					// prop: 'isInvalid',
                    type: 'Invalid',
                    callBack(row) {
                        if (row.isInvalid == '0') {
                            return '正常'
                        } else if (row.isInvalid == '1') {
                            return '已失效'
                        } else {
                            return ''
                        }
                    }
				}, {
					label: '操作',
					type: 'slot',
					slotName: 'operation',
					labelWidth: '120'
			    }]
			}
        }
    },
	computed: {
	    recordsAccept() {
	        return ".wmv, .asf, .rm, .rmvb, .mov, .mp4, .avi"
	    }
	},
    created() {
		if(sessionStorage.getItem('is_edit') && sessionStorage.getItem('is_edit') !== "") {
			this.editState = sessionStorage.getItem('is_edit');
		}
		this.recordsUploadData.meetingId = this.meetingId;
		this.recordsUploadData.type = "2";
        this.getMeetingInfo()
    },
    mounted() {
        this.$nextTick(() => {
            this.tableConfig.height =
            document.body.clientHeight - this.$refs.table.$el.offsetTop - 95;
            window.onresize = debounce(() => {
                if (!this.$refs.table) return
                const height = document.body.clientHeight - this.$refs.table.$el.offsetTop - 95
                this.tableConfig.height = height.toString()
            }, 200)
            console.log(this.tableConfig.height,'this.tableConfig.height',this.$refs.table.$el.offsetTop);
        })
    },
    methods: {
        /**
         * @Description: 返回会议列表组件
         * @DoWhat: 传递 "" 给父组件
         * @UseScenarios:
         * @Attention: 返回上一级，必须传值为 ""
         * @Author:
         * @Date:
         */
        routerList() {
            this.$emit("switchClick", "HasMeeting")
        },
        /**
         * @Description: 获取录屏记录信息
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author:
         * @Date:
         */
        getMeetingInfo() {
            this._fet("/school/schoolMeetingAttachment/listByCondition", {
				meetingId: this.meetingId,
				type: "2"
            }).then(res => {
                if (res.data.code === "200") {
                    this.tableData = res.data.data
                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg)
                }
            })
        },
		// 上传文件校验
		uploadBefore(file) {
		    const typeArr =  ".wmv, .asf, .rm, .rmvb, .mov, .mp4, .avi";
		    let isImg = typeArr.includes(file.name.substring(file.name.lastIndexOf(".")))
		    let isLt600M = file.size / 1024 / 1024 < 600
		    if (!isImg) {
		        this.$message.error("上传正确格式文件！")
		        return false
		    } else if (!isLt600M) {
				this.$message.error("文件过大，请上传600M之内的文件。")
		        return false
		    }
		},
		// 上传文件回调
		uploadSuccess(res, file) {
		    if (res.code === "200") {
				this.$message.success("上传成功");
		        this.getMeetingInfo();
		    } else if (res.code === "400") {
		        this.$message.error(res.msg)
				this.$refs.uploadRef.clearFiles()
		    }else if (res && res.msg) {
                this.$message.warning(res.msg)
            }
		},
		/**
		 * @Description: 预览视频
		 * @DoWhat:
		 * @UseScenarios:
		 * @Attention:
		 * @Author: xwn
		 * @Date: 2023-01-04 16:33:05
		 * @param {*} data
		 */
		handlePreviewVideo(data) {
            let videoType = ['swf', 'avi', 'flv', 'mpg', 'rm', 'mov', 'wav', 'asf', '3gp', 'mkv', 'rmvb', 'mp4']
            let url = data.url.split('/');
            url = url[url.length -1].split('.');
            url = url[url.length -1];

            if (data.url.indexOf('minio') !== -1 && videoType.indexOf(url) == -1) {
                let checkFilePreview = filePreview();
                checkFilePreview.openNewPage(data.url)
            } else {
                let url = decodeURIComponent(data.url) // 要预览视频文件的访问地址
                let Base64 = require("js-base64").Base64
                let previewUrl = `http://124.71.235.241:8012/onlinePreview?url=${encodeURIComponent(Base64.encode(url))}`
                window.open(previewUrl)
            }


		},
		/**
		 * @Description: 录屏记录-删除
		 * @DoWhat:
		 * @UseScenarios:
		 * @Attention:
		 * @Author:
		 * @Date: 2023-01-04
		 */
		 handleDel(data){
		    this.$confirm("此操作将永久删除, 是否继续?", "提示", {
		            confirmButtonText: "确定",
		            cancelButtonText: "取消",
		            type: "warning"
		        }).then(() => {
		            this._fet("/school/schoolMeetingAttachment/delete", {
		                id:data.id,
		                }).then((res) => {
		                    if (res.data.code === "200") {
		                    this.getMeetingInfo()
		                    } else if (res.data && res.data.msg) {
		                        this.$message.error(res.data.msg)
		                    }
		                })
		        }).catch(() => {

		        })
		},
		/**
             * @Description: 录屏--下载文件
             * @DoWhat: 通过url下载文件并自定义下载名称
             * @UseScenarios:
             * @Attention:
             * @Author: xwn
             * @Date: 2023-02-13 15:01:40
             */
			 downlad (urls, fileName){
				//判断文件名称是否带有后缀，没有后缀添加一个.MP4的后缀（解决自动上传的视频无后缀下载无法播放问题）
				var newFileName = fileName.split(".");
                newFileName = newFileName[newFileName.length-1];
				if (fileName === newFileName){
					// fileName = fileName + '.mp4'
					fileName = fileName + urls.match(/.[^.]+$/)[0]
				}
                const x = new window.XMLHttpRequest();
                x.open('GET', urls, true);
                x.responseType = 'blob';
                x.onload = () => {
                    const url = window.URL.createObjectURL(x.response);
                    const a = document.createElement('a');
                    a.href = url;
                    a.target = '_blank'
                    a.download = fileName;
                    a.style.display = 'none'
                    document.body.append(a)
                    a.click();
                };
                x.send();
        },
    }
}
</script>

<style lang="scss" scoped>
    .meeting-screen-recording {

    }
</style>
