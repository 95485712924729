<template>
<!--    <div>会议信息</div>-->
    <div class="meeting-info">
		<!-- 会议管理   详情-->
        <div v-if="!errorShow">
            <loading :loadingShow="loadingShow"></loading>
            <div v-if="!loadingShow" class="content-main">
                <div class="main-box">
                    <div class="body-box" :style="{'height': editState == 'edit' ? 'calc(100% - 30px)' :'100%' }">
                        <div class="basic-box">
                            <div class="title-bar left-bar">视频会议基本数据</div>
                            <div class="basic-list">
								<div class="basic-item">
								    <span>状态</span>
								    <em>{{ meetingData.status === '1'? '进行中' :'已结束' }}</em>
								</div>
                                <div class="basic-item basic-item-edit" v-if="editState === 'edit'">
                                    <span>标题</span>
									<div style="display: inline-block;">
										<el-input type="textarea" :rows="3" v-model="meetingData.title" clearable  maxlength="20" show-word-limit size="mini"></el-input>
									</div>
                                </div>
								<div class="basic-item" v-if="editState === ''">
								    <span>标题</span>
								    <em>{{ meetingData.title || '-' }}</em>
								</div>
                                <div class="basic-item  basic-item-select" v-if="editState === 'edit'">
                                    <span>类型</span>
                                    <el-select size="mini" v-model="meetingData.status" filterable clearable placeholder="请选择类型">
                                    	<el-option v-for="item in statusOptions" :label="item.label"
                                    		:value="item.value" :key="item.value"></el-option>
                                    </el-select>
                                </div>
								<div class="basic-item" v-if="editState === ''">
								    <span>类型</span>
								    <em>{{ meetingData.typeName || '-' }}</em>
								</div>
								<div class="basic-item">
								    <span>方式</span>
								    <em>{{  meetingData.mode ==='1' ? '自主发起' :  meetingData.mode ==='2' ? '预约' : '手动添加'}}</em>
								</div>
                                <div class="basic-item  basic-item-select" v-if="editState === 'edit'">
                                    <span>科目</span>
                                    <el-select size="mini" v-model="subjectId" filterable clearable
										@change="getLSubjectName" placeholder="请选择科目">
										<el-option v-for="item in subjectOptions" :label="item.label"
                                   		:value="item.value" :key="item.value"></el-option>
                                    </el-select>
                                </div>
								<div class="basic-item" v-if="editState === ''">
								    <span>科目</span>
								    <em>{{ meetingData.subject || '-' }}</em>
								</div>
                                <div class="basic-item  basic-item-select" v-if="editState === 'edit'">
                                    <span>年级</span>
                                    <!-- <el-select size="mini" v-model="gradeId" filterable clearable
									 @change="getLGradeName" placeholder="请选择年级">
                                    	<el-option v-for="item in gradeOptions" :label="item.label"
                                    		:value="item.value" :key="item.value"></el-option>
                                    </el-select> -->

                                    <el-cascader
                                        ref="cascaderFormGradeRef"
                                        size="mini"
                                        v-model="gradeId"
                                        :props="{
                                            label: 'name',
                                            multiple: false,
                                            value: 'id',
                                            children: 'child',
                                            checkStrictly: true,
                                            emitPath:false,
                                        }"
                                        :options="gradeOptions"
                                        collapse-tags
                                        :placeholder="'请选择年级'"
                                        :clearable="true"
                                        filterable
                                        @change="getLGradeName"
                                    ></el-cascader>
                                </div>
								<div class="basic-item" v-if="editState === ''">
								    <span>年级</span>
								    <em>{{ gradeName || '-' }}</em>
								</div>
								<div class="basic-item">
								    <span>创建人</span>
								    <em>{{ meetingData.createName || '-' }}</em>
								</div>
                                <!-- 仅预约会议显示 -->
                                <div class="basic-item" v-if="meetingData.mode ==='2'">
								    <span>预约时间</span>
								    <em v-if="meetingData.appointStartTime">{{ startTime?.slice(0, 16) || '-' }}-{{ endTime?.slice(0, 16) }}</em>
								</div>
								<div class="basic-item">
								    <span>创建时间</span>
								    <em>{{ meetingData.createTime?.slice(0, 16) || '-' }}</em>
								</div>
                                <div class="basic-item">
                                    <span>开始时间</span>
                                    <em>{{ meetingData.startTime?.slice(0, 16) || '-' }}</em>
                                </div>
                                <div class="basic-item">
                                    <span>时长</span>
                                    <em>{{ meetingData.meetingDuration?.slice(0, 5) || '-' }}</em>
                                </div>
                                <div class="basic-item">
                                    <span>发起人</span>
                                    <em>{{ meetingData.conferenceSponsor || '-' }}</em>
                                </div>
                                <div class="basic-item">
                                    <span>总人数</span>
                                    <em>{{ meetingData.meetingNumber || '-' }}</em>
                                </div>
                                <div class="basic-item">
                                    <span>已入会</span>
                                    <em>{{ meetingData.joinedNumber || '-' }}</em>
                                </div>
                                <div class="basic-item">
                                    <span class="text_span">
                                        <span class="span_text">时长不足</span>
                                        <el-tooltip class="first_enter" effect="dark" content="参会时长不足人数" placement="top" :offset="30">
                                            <i class="el-icon-question label-description"></i>
                                        </el-tooltip>
                                    </span>
                                    <em>{{ meetingData.notEnoughTimeNumber || '-' }}</em>
                                </div>
                                <div class="basic-item">
                                    <span>未入会</span>
                                    <em>{{ meetingData.noJoinedNumber || '-' }}</em>
                                </div>
                                <div class="basic-item">
                                    <span>参会比例</span>
                                    <em>{{ meetingData.participationRatio || '-' }}</em>
                                </div>
                                <!-- 仅预约会议显示 -->
                                <div class="basic-item" v-if="meetingData.mode ==='2'">
                                    <span>提醒</span>
                                    <em>{{ meetingData.remindTime || '-' }}</em>
                                </div>
                            </div>
                        </div>
                        <div class="table-box">
                            <div class="title_left">
                                <div class="title-bar">视频会议参会者数据</div>
                                <div class="left_color"></div>
                            </div>
                            <table-data :config="table_config" :tableData="table_data"
                                backgroundHeader="#FAFBFC"
                                ref="table" id="table"></table-data>
                        </div>
                    </div>
                    <div class="common-form-layout" v-if="editState === 'edit'">
                        <form-submit-btn>
                            <div slot="formSubmitBtn">
                                <el-button type="primary" @click="basicSubmitClick">保存</el-button>
                                <el-button type="enquiry" @click="basicBackClick">取消</el-button>
                            </div>
                        </form-submit-btn>
                    </div>

                </div>

            </div>
        </div>
        <error v-else :errorShow="errorShow"></error>
    </div>
</template>

<script>
import TableData from "../Sub/TableData"
import { Loading,debounce } from "common-local"
// import onResize from "@/mixins/onResize"
import FormSubmitBtn from "../Sub/FormSubmitBtn/index.vue";
import { cleanTree } from "@/libs/utils.js";

export default {
    // mixins:[onResize],
    components: { Loading,TableData,FormSubmitBtn},
    props: {
        meetingId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            loadingShow: false,
            errorShow: false,
			// 会议类型
			meetingsType: "",
			// 是否编辑
			editState: "",
            table_config: {
                thead: [
                    {
                        label: "姓名",
                        prop: "name"
                    },
                    {
                        label: "班级",
                        prop: "className"
                    },
                    {
                        label: "年级",
                        prop: "grade"
                    },
                    {
                        label: "学校",
                        prop: "school",
                        labelWidth: "160"
                    },
                    {
                        label: "入会时间",
                        labelDescription: "首次入会时间",
                        prop: "firstJoiningTime",
                        labelWidth: "160",
                        align: "left",
                        type: "function",
                        callBack: (row) => {
                            if (row.firstJoiningTime) {
                                return row.firstJoiningTime.slice(0, 16)
                            }
                        }
                    },
                    {
                        label: "离开时间",
                        labelDescription: "最后离开时间",
                        prop: "lastDepartureTime",
                        labelWidth: "160",
                        align: "left",
                        type: "function",
                        callBack: (row) => {
                            if (row.lastDepartureTime) {
                                return row.lastDepartureTime.slice(0, 16)
                            }
                        }
                    },
                    {
                        label: "时长",
                        prop: "duration",
                        align: "left",
                        type: "function",
                        callBack: (row) => {
                            if (row.duration) {
                                return row.duration.slice(0, row.duration.length - 3)
                            }
                        }
                    },
                    {
                        label: "缺席原因",
                        prop: "notJoiningReason",
                    },
                    {
                        label: "时长不足",
                        prop: "isEnoughTime",
                        labelDescription: "参会时长是否不足",
                        labelWidth: "100",
                        align: "center",
                        type: "function",
                        callBack(row) {
                            return {
                                1: "是",
                                0: "否",
                            }[+row.isEnoughTime];
                        },
                    },
                ],
                check: false,
                height: ''
            },
            table_data: [],
            headerCellStyle: {
                height: "50px",
                backgroundColor: "#fff",
                borderBottom:'none'
            },
            rowStyle: {
                height: "50px",
            },
            meetingData: {},
			// 类型
			statusId: "",
			statusOptions: [],
			// 学科信息
			subjectId: "",
			subjectOptions: [],
			// 年级信息
			gradeId: "",
			gradeOptions: [],
            startTime: "",//预约时间开始时间
            endTime: "",//预约时间的结束时间
        }
    },
    computed: {
        gradeName() {
            let parentName = "";
            let gradeName = "";
            parentName = this.meetingData.parentName ? `${this.meetingData.parentName}/` : "";

            gradeName = this.meetingData.grade ?this.meetingData.grade : "";
            return `${parentName}${gradeName}`
        }
    },
    created() {
        this.$eventDispatch('setGlobalPageType','form')
		if(sessionStorage.getItem('is_edit') && sessionStorage.getItem('is_edit') !== "") {
			this.editState = sessionStorage.getItem('is_edit');
		}
		if(sessionStorage.getItem('meeting_type') && sessionStorage.getItem('meeting_type') !== "") {
			this.meetingsType = sessionStorage.getItem('meeting_type');
		}
		this.getTypes();
		this.getGradList();
		this.getSubjectList();
        this.getMeetingInfo()
    },
    mounted() {
        this.$nextTick(() => {
            this.table_config.height =
            document.body.clientHeight - this.$refs.table.$el.offsetTop - 140;
            window.onresize = debounce(() => {
                if (!this.$refs.table) return
                const height = document.body.clientHeight - this.$refs.table.$el.offsetTop - 140
                this.table_config.height = height.toString()
            }, 200)
            console.log(this.table_config.height,'this.table_config.height',this.$refs.table.$el.offsetTop);
        })
    },
    methods: {
        /**
         * @Description: 返回会议列表组件
         * @DoWhat: 传递 "" 给父组件
         * @UseScenarios:
         * @Attention: 返回上一级，必须传值为 ""
         * @Author:
         * @Date:
         */
        routerList() {
            this.$emit("switchClick", "HasMeeting")
            this.$eventDispatch('setGlobalPageType','list')
        },
		// 会议获取类型列表
		getTypes() {
			this._fet("/school/schoolDictValue/getDictValueList", {
				dickKeyList:["meetingType"],
				schoolId: this.$store.state.schoolId
			}).then((res) => {
				if (res.status === 0) {
					let dataList = res.data;
					if(dataList.length > 0) {
						dataList.forEach(item => {
							item.value = item.id;
							item.label = item.name;
						});
					}
					this.statusOptions = dataList;
				} else if (res.data && res.data.msg) {
					this.$message.error(res.data.msg)
				}
			})
		},
		// 年级列表
		getGradList() {
            let obj = {
                schoolId: this.$store.state.schoolId,
            };
            // Object.assign(obj, {jobScope: ["1", "2", "3"]});
            this._fet(
                "/school/schoolOrgan/getGradeOfTree",
                obj,
            ).then((res) => {
                if (res.data.code === "200") {
                    let options = cleanTree(res.data.data,'child');
                    
                    this.gradeOptions = options;
                } else {
                    this.$message.error("数据请求失败，请重试");
                }
            });

			// this._fet("/school/schoolOrgan/listByCondition", {
			// 	organType: "3",
			// 	schoolId: this.$store.state.schoolId
			// }).then((res) => {
			// 	if (res.data.code === "200") {
			// 		let dataList = res.data.data.list;
			// 		dataList.forEach(item => {
			// 			item.value = item.id;
			// 			item.label = item.name;
			// 		});
			// 		this.gradeOptions = dataList;

			// 	} else if (res.data && res.data.msg) {
			// 		this.$message.error(res.data.msg)
			// 	}
			// })
		},
		// 获取科目列表数据
		getSubjectList() {
			this._fet("/school/schoolDictValue/getDictValueList", {
				dickKeyList: ["teachingSubjects"],
				schoolId: this.$store.state.schoolId
			}).then((res) => {

				if (res.status === 0) {
					let dataList = res.data;
					dataList.forEach(item => {
						item.value = item.id;
						item.label = item.name;
					});
					this.subjectOptions = dataList;
				} else if (res.data && res.data.msg) {
					this.$message.error(res.data.msg)
				}
			})
		},
        /**
         * @Description: 获取会议信息
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author:
         * @Date: 2023-2-10 11:10:58
         */
        getMeetingInfo() {
            this._fet("/school/schoolMeetingAnalysis/getInfoById", {
                id: this.meetingId
            }).then(res => {
                if (res.data.code === "200") {
                    this.meetingData = res.data.data
                    this.meetingData.typeName=this.meetingData.typeName+'('+this.meetingData.validTime+')'
					if(this.editState === 'edit') {
						this.meetingData.status = res.data.data.typeId;
						this.meetingData.subject = res.data.data.subject;
						this.meetingData.grade = res.data.data.grade;
						this.subjectId = res.data.data.subjectId;
						this.gradeId = res.data.data.gradeId;
					}
                    //修改预约时间的格式
                    if(this.meetingData.appointEndTime){
                        var dateObj = new Date(this.meetingData.appointEndTime)
                        var hours=dateObj.getHours()<10 ? "0"+dateObj.getHours() : dateObj.getHours();
                        var minutes=dateObj.getMinutes()<10 ? "0"+dateObj.getMinutes() : dateObj.getMinutes();
                        this.endTime = hours+":"+minutes;
                    }
                    if(this.meetingData.appointStartTime){
                        var dateObj = new Date(this.meetingData.appointStartTime)
                        var year = dateObj.getFullYear();
                        var month= dateObj.getMonth()+1<10 ? "0"+(dateObj.getMonth()+1) : dateObj.getMonth()+1;
                        var day=dateObj.getDate()<10 ? "0"+dateObj.getDate() : dateObj.getDate();
                        var hours=dateObj.getHours()<10 ? "0"+dateObj.getHours() : dateObj.getHours();
                        var minutes=dateObj.getMinutes()<10 ? "0"+dateObj.getMinutes() : dateObj.getMinutes();
                        this.startTime = year +"-"+month+"-"+day+" "+hours+":"+minutes
                    }
                    this.table_data=this.meetingData.attendeeList
                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg)
                }
            })
        },
		/**
		 * @Description: 获取科目名称
		 * @DoWhat:
		 * @UseScenarios:
		 * @Attention:
		 * @Author: xgz
		 * @Date: 2023-2-13 15:44:18
		 */
		getLSubjectName(val) {
			let dataList = this.subjectOptions;
			if(val) {
				for(let j = 0; j < dataList.length; j++) {
					if(val === dataList[j].id) {
						this.meetingData.subject =  dataList[j].name
					}
				}
			}
		},
		/**
		 * @Description: 获取年级名称
		 * @DoWhat:
		 * @UseScenarios:
		 * @Attention:
		 * @Author: xgz
		 * @Date: 2023-2-13 15:44:30
		 */
		getLGradeName(val) {
			// let dataList = this.gradeOptions;
			// if(val) {
			// 	for(let j = 0; j < dataList.length; j++) {
			// 		if(val === dataList[j].id) {
			// 			this.meetingData.grade = dataList[j].name
			// 		}
			// 	}
			// }

            if(!val){
                this.meetingData.gradeId = "";
                this.meetingData.grade = "";
                this.meetingData.parentId = "";
                this.meetingData.parentName = "";
                return
            }

            let nodes = this.$refs.cascaderFormGradeRef.getCheckedNodes();

            console.log(nodes);
            this.meetingData.gradeId = nodes[0].value.toString();
            this.meetingData.grade = nodes[0].label.toString();

            if(nodes[0].parent) {
                let parent = nodes[0].parent
                this.meetingData.parentId = parent.value.toString();
                this.meetingData.parentName = parent.label.toString();;
            } else {
                this.meetingData.parentId = "";
                this.meetingData.parentName = "";
                return
            }
		},
		/**
		 * @Description: 基础信息编辑提交
		 * @DoWhat:
		 * @UseScenarios:
		 * @Attention:
		 * @Author: xgz
		 * @Date: 2023-2-10 11:11:33
		 */
		basicSubmitClick() {
			// 编辑提交前验证必填项是否为空值
			if(this.meetingData.title === "") {
				this.$message.warning("请输入标题");
				return false;
			}

			if(this.meetingData.status === "") {
				this.$message.warning("请选择类型");
				return false;
			}

			if(this.subjectId === "") {
				this.meetingData.subject = ""
			}

			if(this.gradeId === "") {
				this.meetingData.grade = ""
                this.meetingData.parentId = ""
				this.meetingData.parentName = ""
			}

			this._fet("/school/schoolMeetingAnalysis/edit", {
			    "id": this.meetingId,
				"title": this.meetingData.title,
				"typeId": this.meetingData.status,
				"subject": this.meetingData.subject,
				"subjectId": this.subjectId,
				"grade": this.meetingData.grade,
				"gradeId": this.gradeId,
                "parentId": this.meetingData.parentId,
                "parentName": this.meetingData.parentName,
			}).then(res => {
			    if (res.data.code === "200") {
			        this.getMeetingInfo();
					this.$message.success("编辑成功");
			    } else if (res.data && res.data.msg) {
			        this.$message.error(res.data.msg)
			    }
			})
		},
        basicBackClick () {
            this.$emit('basicBackClick')
        }
    }
}
</script>

<style lang="scss" scoped>
    .meeting-info {
		.el-textarea__inner {
			resize: none;
		}

        .content-wrapper {
            box-sizing: border-box;
        }

        .main-box {
            padding-bottom: 20px;
            box-sizing: border-box;
            height: calc(100vh - 133px);
        }


        .body-box {
            display: flex;
            .basic-box {
                background-color: #fff;
                width: 350px;
                padding: 22px;
                flex-shrink: 0;
                padding-bottom: 0;
                padding-left: 20px;
                border-radius: 4px 4px 0 0;
                margin-right: 10px;
                border-bottom-left-radius: 4px;
                border-bottom-right-radius: 4px;
                .basic-list {
                    overflow-y: auto;
                    height: calc(100% - 35px);
                }

                .basic-item {
                    display: flex;
                    color: #808387;
                    font-size: 14px;
                    margin-top: 18px;
                    align-items: flex-start;

                    &:first-child {
                        margin-top: 0;
                    }
                }

                span {
                    width: 100px;
                    margin-right: 30px;
                    text-align: right;
                }

                em {
                    width: 50%;
                    color: #363A40;
                    font-style: normal;
                }
                .title-bar {
                    line-height: 1;
                    color: #2B2F33;
                    font-weight: bold;
                    font-size: 15px;
                    padding-left: 10px;
                    border-left: solid 3px #3C7FFF;
                }
                .left-bar {
                    margin-bottom: 18px;
                }
                .text_span{
                    position: relative;
                    .span_text{
                        margin-right: 16px;
                    }
                }
                .first_enter{
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }

            .table-box {
                height: 100%;
                padding: 10px;
                border-radius: 4px 4px 4px 4px;
                box-sizing: border-box;
                width: calc(100% - 400px);
                background-color: #fff;
                .title_left{
                    height: 38px;
                    margin-bottom: 20px;
                    line-height: 38px;
                    background: #F7F8FA;
                    position: relative;

                }
                .title-bar{
                    color: #2B2F33;
                    font-weight: bold;
                    font-size: 15px;
                    margin-left: 22px;
                }
                .left_color{
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 13px;
                    width: 3px;
                    height: 15px;
                    background-color: #3C7FFF;
                }

                .text-overflow {
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }
        }

		.basic-item-edit .el-select .el-input .el-input__inner {
			height: 28px;
			line-height: 28px;
		}

		.basic-item-select .el-select .el-input .el-input__inner {
            width: 170px;
			height: 28px;
			line-height: 28px;
		}

		.basic-box .basic-item-edit span {
			width: 110px;
		}

		.basic-box .basic-item-select span {
			width: 110px;
		}
        .basic-box .basic-item-select .el-input__suffix {
			width: 10px;
            margin-right: 10px;
		}
    }
    .firstEnter{
        margin-left: 10px;
    }
    ::v-deep .el-table th.el-table__cell > .cell{
        color: #505559;
        font-size: 14px;
        font-weight: bold;
    }
    .el-textarea__inner{
        width: 193px;
    }
</style>
